import merge from 'deepmerge';
import defaultThemeColors from 'gatsby-theme-blog/src/gatsby-plugin-theme-ui/colors';

const white = 'white';
const secondary1 = '#4B748E';
const secondary4 = '#243540';

export default merge(defaultThemeColors, {
  text: 'rgba(0, 0, 0, 0.5)',
  background: white,
  primary: secondary4,
  modes: {
    dark: {
      text: white,
      background: '#202020',
      primary: secondary1,
      highlight: secondary4,
    },
  },
});
